<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="header_section px-5 bd-b">
            <h4 class="menu_header">Manage Store</h4>
          </div>
          <div class="dash_card_body">


            <div class="row">
              <div class="col-md-4">
                <div class="manage_store">
                  <div v-if="!enableEdit">
                    <form class="form-horizontal" @submit.prevent="configureStores">
                      <div class="form-group">
                        <label class="control-label" for="email">Store:</label>
                        <div>
                          <Multiselect class="form-control" id="store_"  @blur="v$.foodieStore.$touch"  v-model="foodieStore" :close-on-select="true" :options="soptions" :custom-label="name" :searchable="true" :placeholder="'Stores ('+soptions.length +')'" label="name" track-by="name"></Multiselect>

                          <!-- <Multiselect v-model="foodieStore"
                            placeholder="Select Store" :close-on-select="true"
                            :options="soptions" :custom-label="name"
                            :searchable="true"  label="name" track-by="name">
                          </Multiselect> -->



                          <!-- <select class="form-control" id="store_" v-model="foodieStore"  @blur="v$.foodieStore.$touch">
                            <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                          </select> -->
                        </div>
                        <div class="input_error" v-if="v$.foodieStore.$error">Store is required.</div>

                      </div>
                      <div class="form-group">
                        <label class="control-label" for="pwd">Channel:</label>
                        <div>
                          <select class="form-control" v-model="thirdParty" @blur="v$.thirdParty.$touch" @click="getChannelStores()">
                            <option v-for="channel in channels" :key="channel.id" :value="channel">{{channel.channel_name}}</option>
                          </select>
                        </div>
                        <div class="input_error" v-if="v$.thirdParty.$error">Channel is required.</div>

                      </div>
                      <div class="form-group" v-if="thirdParty && thirdParty.channel_name.toLowerCase() !='uber'">
                        <input type="checkbox" v-model="newStore" id="new_store"  name="vehicle1" value="1">
                        <label for="vehicle1"> &nbsp; New store</label><br>
                      </div>
                      <div class="form-group" v-if="!newStore">
                        <label class="control-label" for="pwd">Channel Store:</label>
                        <div>
                          <Multiselect class="form-control"  @blur="v$.thrdpartyStore.$touch"  v-model="thrdpartyStore" :close-on-select="true" :options="scoptions" :custom-label="name" :searchable="true"  label="name" track-by="name"></Multiselect>

                          <!-- <select class="form-control" v-model="thrdpartyStore" @blur="v$.thrdpartyStore.$touch">
                            <option v-for="chanel_store in chanelStores" :key="chanel_store.store_id" :value="chanel_store">{{chanel_store.name}}</option>
                          </select> -->
                        </div>

                        <div class="input_error" v-if="v$.thrdpartyStore.$error">Channel Store is required.</div>

                      </div>
                      <div v-else>
                        <div class="form-group">
                          <label class="control-label" for="email">Store Name:</label>
                          <div>
                            <input type="text" class="form-control" v-model="storeName" :placeholder="'Store name on ' + thirdParty.channel_name">
                          </div>
                          <!-- <div class="input_error" v-if="v$.foodieStore.$error">Store is required.</div> -->

                        </div>
                        <div class="form-group">
                          <label class="control-label" for="email">Store ID:</label>
                          <div>
                            <input type="text" class="form-control" v-model="storeId" :placeholder="'Store id on  ' + thirdParty.channel_name">
                          </div>
                          <!-- <div class="input_error" v-if="v$.foodieStore.$error">Store is required.</div> -->

                        </div>
                      </div>
                      <div class="form-group">
                        <div>
                          <button type="submit"  class="btn btn-orange">
                            <span v-if="spinner" v-html="searchspinner"></span>
                            <span v-else>Save Configuration</span></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="manage_store_right">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <tr>
                          <th width="5px"></th>
                          <th scope="col">Store Name</th>
                          <th scope="col">Status</th>
                          <th width="50%" scope="col">Configured Stores</th>
                        </tr>

                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="(c_store,csindx) in configuredstores" :key="c_store.id">
                          <td>{{csindx + 1}}.</td>
                          <td>{{c_store.name}}</td>
                          <td>
                            <span v-if="c_store.status" style="color:green;">
                              Published
                            </span>
                            <span v-else style="color:red;">
                              Unpublished
                            </span>
                          </td>
                          <td>
                            <div class="manage-wrapper" v-for="cc_str in c_store.configured_stores" :key="cc_str.id">
                              <div class="text-wrap">
                                <span><b>Name:</b> {{cc_str.name}}</span>
                                <span><b>Channel:</b> {{cc_str.channel}}</span>
                                <span><b>Id:</b> {{ cc_str.id }}</span>
                              </div>
                              <div class="manage-btn mt-3">
                                <button :class="[{ 'btn-act': !cc_str.integration_enabled },{ 'btn-dgrey': cc_str.integration_enabled }]" class="btn  mr-3" @click="activateStoreIntegration(cc_str.local_id,cc_str.integration_enabled)">
                                  <span v-if="activatingStore == cc_str.local_id"><i class='fa fa-spinner fa-spin'></i></span><span v-if="!cc_str.integration_enabled"> Activate </span>
                                  <span v-else>Deactivate</span> Integration</button>
                                <button class="btn btn-sm btn-danger mr-3" @click="removeStore(cc_str,c_store)">Delete <i class='fa fa-trash'></i></button>
                                <router-link :to="{name: 'activity_log',params:{id: cc_str.local_id}, query:{logID: 6}}">
                                  <button class="btn btn-sm btn-primary">Logs</button>
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="!loading">
                        <tr>
                          <td colspan="8">
                            <div id="pagination" v-if="totalPages > 1">
                              <ul class="showItems">
                                <li>Show Items:
                                  <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="30">30</option>
                                    <option :value="40">40</option>
                                    <option :value="50">50</option>
                                  </select>
                                </li>

                              </ul>
                              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                            </div>

                          </td>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();

export default {
  components: {
    Pagination,
    Multiselect
  },
  setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            stores: [],
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            foodieStore: '',
            thrdpartyStore: '',
            thirdParty: '',
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            enterpageno: '',
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Configuring...",
            channels: [],
            chanelStores:[],
            configuredstores: [],
            newStore:false,
            activatingStore: false,
            storeName: '',
            storeId: '',
            soptions:[],
            scoptions:[]
        }
    },
    validations() {
        return {
            foodieStore: { required },
            thrdpartyStore: { required },
            thirdParty: { required },
        }
    },
    watch:{
      foodieStore(){
        if(this.foodieStore){
          const store = this.stores.filter((x) => x.id == this.foodieStore)
          this.storeName = store[0].branch_name
        }
      },
      newStore(){
        if(this.newStore){
          const guuid =  this.generateUUID()
          this.storeId = guuid
        }
      }

    },
    created: async function () {
        this.getConfigureStores();
        this.getStores();
        this.getThirdParties();
    },
    methods: {
      generateUUID() { // Public Domain/MIT
            let d = new Date().getTime();//Timestamp
            let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16;//random number between 0 and 16
                if(d > 0){//Use timestamp until depleted
                    r = (d + r)%16 | 0;
                    d = Math.floor(d/16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r)%16 | 0;
                    d2 = Math.floor(d2/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },
        async getConfigureStores() {
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.baseUrl + "/third-party-branch?merchant_id="+this.merchantID;
          this.loading = true;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.loading = false;
                    this.configuredstores = response.data.data;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        },
        getEditData(thirdPartyStore,foodieStoreData){
          this.enableEdit = true;
          this.thrdpartyStore = thirdPartyStore.id;
          this.foodieStore = foodieStoreData.id;
        },
        async configureStores(){
          this.spinner = true;
          const isFormCorrect = await this.v$.$validate()
          if (!isFormCorrect && !this.newStore) {
                this.spinner = false;
                return
            }
            const payload = {
                "branch_id": this.foodieStore,
                "store_id": !this.newStore? this.thrdpartyStore.store_id : this.storeId,
                "third_party_setting_id": this.thirdParty.id,
                "store_name": !this.newStore ? this.thrdpartyStore.name : this.storeName,
                "merchant_id":parseInt(this.merchantID),
                "integration_enabled": this.thrdpartyStore.integration_enabled
            };
            const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };

            const url = this.baseUrl + '/third-party-branch'
            this.axios.post(url, payload,config).then((response) => {
                    if (response.status === 201) {
                        toast.success("Configuration was successfully");
                        this.spinner = false;
                    }else {
                        this.spinner = false;
                        toast.error(response.data.message);
                    }
                    this.getConfigureStores();
                })
                .catch((error) => {
                    this.spinner = false;
                    if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
                })
            // ds
        },
        async removeStore(confStore,store){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.baseUrl + "/third-party-branch/"+confStore.local_id;
          this.axios.delete(url,{data:{"merchant_id": parseInt(this.merchantID)}, ...config}).then((response) =>{
            if(response.status === 200){
              this.getConfigureStores();
              toast.success(confStore.channel+" store "+confStore.name+" removed from association with branch "+store.name+" on foodie")
            }
          }).catch((error) => {
            toast.error('Ooops! '+ error);
            if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
          })
        },
        async getThirdParties(){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.baseUrl + "/third-party-setting?merchant_id="+this.merchantID;
          this.axios.get(url,config).then((response) => {
            if(response.status === 200){
              this.channels = response.data.data
            }
          });
        },
        async getStores() {
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
            const url = this.baseUrl + "/branch?merchant_id="+this.merchantID;
            this.axios.get(url,config).then((response) => {
                if(response.status === 200){
                    const stores = response.data.data;
                    this.stores = stores
                    for(let i=0; i < stores.length ; i++){
                      this.soptions.push({
                            "value": stores[i].id,
                            "name": stores[i].branch_name
                        })
                    }
                }
            })
        },
         onPageChange(page) {
            this.page = page
            this.getConfigureStores();
        },
        onChangeRecordsPerPage() {
            this.getConfigureStores();
        },
        getChannelStores(){
          if(this.thirdParty.channel_name === undefined) {
            return;
          }

          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
           const url = `${this.baseUrl}/third-party-branch/fetch?channel=${this.thirdParty.channel_name}&merchant_id=${this.merchantID}`;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.chanelStores = response.data.data;
                    this.scoptions = []
                    for(let i=0; i < this.chanelStores.length ; i++){
                      this.scoptions.push({
                            "value": this.chanelStores[i],
                            "name": this.chanelStores[i].name
                        })

                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        },
        activateStoreIntegration(storeId,activationStatus){
          const status = !activationStatus
          this.activatingStore = storeId;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.baseUrl}/third-party-branch/${storeId}/toggle-integration`;
          const payload = {
              "merchant_id": parseInt(this.merchantID),
              "integration_enabled": status
          };
          this.axios.patch(url,payload,config).then((response) => {
            if(response.status ===200){
              this.getConfigureStores();
            }
            //
          }).catch((error) => {
            if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            //
          }).finally(() => {
            this.activatingStore = false;
          });
        }
    },
     mixins: [ReusableDataMixins]
}
</script>
